<template>
<div>
    <v-card flat class="transparent">
        <v-card-title>
            {{ $t('tfasyl-almkhtt') }}
            <span class="font-weight-bold mx-2">
                {{ workflow.name }}
            </span>
            <v-spacer></v-spacer>
            <v-chip>
                {{ $utils.formatDate(workflow.created, false) }}
            </v-chip>
            <v-col cols="12">
                <v-card outlined>
                    <v-tabs v-model="tab" background-color="transparent"  active-class="primary--text third">
                        <v-tab class="black--text"> {{ $t('alnwdat-alreysyh') }} </v-tab>
                        <v-tab class="black--text"> {{ $t('alnwdat-alfrayh') }}</v-tab>
                    </v-tabs>
                </v-card>
            </v-col>
            <v-card-text>
                <MainNodes v-if="tab == 0" />
                <SubNodes v-if="tab == 1" />
            </v-card-text>
        </v-card-title>
    </v-card>
</div>
</template>

<script>
export default {
    components: {
        SubNodes: () => import("./subNodes/subNodes.vue"),
        MainNodes: () => import("./mainNodes/mainNodes.vue"),
    },
    data() {
        return {
            tab: 0,
        };
    },
    computed: {
        workflow() {
            return this.$global.state.workflow;
        },
    },
};
</script>
